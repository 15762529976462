import request from '../request';

// 商品列表
export function getuseInfo(data) {
  return request({
    url: '/Item/getItemList',
    method: 'get',
    params: data,
  });
}

// 商品分组
export function getAllGroup(data) {
  return request({
    url: '/ItemGroup/getAllGroup',
    method: 'get',
    params: data,
  });
}

// 品牌
export function getAllBrand(data) {
  return request({
    url: '/SyscategoryBrand/getAll',
    method: 'get',
    params: data,
  });
}
// 欧代
export function getAllEu(data) {
  return request({
    url: '/supplierEu/getEuAgentList',
    method: 'get',
    params: data,
  });
}
// 商品审核状态
export function returnGoodsStatus(data) {
  return request({
    url: '/item/returnGoodsStatus',
    method: 'get',
    params: data,
  });
}
// 下架原因
export function returnInstockRea(data) {
  return request({
    url: '/item/returnInstockRea',
    method: 'get',
    params: data,
  });
}
//所有类目
export function getAllCategoryInfo(data) {
  return request({
    url: '/CategoryInfo/getAllCategoryInfo',
    method: 'get',
    params: data,
  });
}
// 商品分组编辑
export function itemGroupRelationSave(data) {
  return request({
    url: '/item/itemGroupRelationSave',
    method: 'post',
    data: data,
  });
}

// 修改目标价
export function editTargetPrice(data) {
  return request({
    url: '/item/editTargetPrice',
    method: 'post',
    data: data,
  });
}

// 查看价格/目标价格 历史记录
export function targetPriceList(data) {
  return request({
    url: '/item/targetPriceList',
    method: 'get',
    params: data,
  });
}

// 修改补贴
export function editGoodsSubsidy(data) {
  return request({
    url: '/Subsidy/updateSubsidyPhase',
    method: 'post',
    data: data,
  });
}

// 获取利润点的历史记录
export function profitPointsList(data) {
  return request({
    url: '/item/profitPointsList',
    method: 'get',
    params: data,
  });
}

// 查看审核记录
export function goodsExamineList(data) {
  return request({
    url: '/Item/goodsExamineList',
    method: 'get',
    params: data,
  });
}

// 查看变更记录
export function goodsChangeList(data) {
  return request({
    url: '/item/goodsChangeList',
    method: 'get',
    params: data,
  });
}

// 查看平台列表
export function platform(data) {
  return request({
    url: '/item/platform',
    method: 'get',
    params: data,
  });
}

// 编辑参考平台
export function referencePrince(data) {
  return request({
    url: '/item/referencePrince',
    method: 'get',
    params: data,
  });
}

// 获取参考价格平台列表
export function referencePrinceList(data) {
  return request({
    url: '/item/referencePrinceList',
    method: 'get',
    params: data,
  });
}

//参考价格删除
export function referencePrinceDel(data) {
  return request({
    url: '/item/referencePrinceDel',
    method: 'post',
    data: data,
  });
}

// 商品下架
export function itemInstock(data) {
  return request({
    url: '/Item/itemInstock',
    method: 'post',
    data: data,
  });
}

// 获取参考价格平台列表
export function getSkuInfo(data) {
  return request({
    url: '/Item/getSkuInfo',
    method: 'get',
    params: data,
  });
}

// 修改服务费
export function editCommission(data) {
  return request({
    url: '/item/editCommission',
    method: 'post',
    data: data,
  });
}

// 驳回
export function itemExamine(data) {
  return request({
    url: '/Item/itemExamine',
    method: 'post',
    data: data,
  });
}

// 驳回
export function itemDelete(data) {
  return request({
    url: '/Item/itemDelete',
    method: 'post',
    data: data,
  });
}

// 可售区域
export function itemSaleableArea(data) {
  return request({
    url: '/item/itemSaleableArea',
    method: 'get',
    params: data,
  });
}

// 发起导出接口
export function itemAdditionalService(data) {
  return request({
    url: '/Item/itemAdditionalService',
    method: 'post',
    data: data,
  });
}
// 导出列表接口
export function exportTaskList(data) {
  return request({
    url: '/ExportTask/exportTaskList',
    method: 'get',
    params: data,
  });
}
// 商品信息下载列表接口
export function downLoadList(data) {
  return request({
    url: '/item/downLoadList',
    method: 'get',
    params: data,
  });
}
// 商品利润点统计
export function profitPointAStatistics(data) {
  return request({
    url: '/Item/profitPointAStatistics',
    method: 'get',
    params: data,
  });
}
// 保存更新商品备注
export function itemRemark(data) {
  return request({
    url: '/item/itemRemark',
    method: 'post',
    data: data,
  });
}
// 导出无物流商品
export function getNoLogInfoGoods(data) {
  return request({
    url: '/item/addTask',
    method: 'get',
    params: data,
  });
}
